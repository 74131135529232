<form [formGroup]="filterForm">

    <div class="row">
        <div class="col-md-1">
            <select style="width:70px" formControlName="limit" class="form-control"
                (change)="this.onFilterChanged.emit(filterForm.value)">
                <ng-container *ngFor="let item of limitOptions; let i = index">
                    <option value={{item}}>{{item}}</option>
                </ng-container>
            </select>
        </div>
        <div class="col-md-5"></div>
        <div class="col-md-6">

            <div class="input-group mb-3">
                <input type="text" placeholder="Search by name, phone, patient id..." class="form-control focus-none"
                    formControlName="searchkey">
                <div class="input-group-prepend">
                    <button class="btn btn-primary focus-none" type="submit"
                        (click)="this.onFilterChanged.emit(filterForm.value)">Search</button>
                </div>
            </div>

        </div>
    </div>
</form>