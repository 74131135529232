<div class="container-fluid">
  <div class="row justify-content-between align-items-center p-2">
    <div class="col-md-2">
      <img routerLink="/" src="assets/hh_logo.png" alt="Logo" class="logo"  />
    </div>
    <div class="col-md-4">
      <h4>{{title}}</h4>
    </div>
    <div class="col-md-4">
      <h5>Phone: 9333779450 / 9883276647</h5>
      <h5>Email: info@healthyhearing.co.in</h5>
    </div>
    <div class="col-md-2">
      <div class="text-right">
        <button type="button" class="btn btn-large btn-info" (click)="logout()">
          <div class="d-flex flex-column align-items-center p-1">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-box-arrow-right" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
              <path fill-rule="evenodd"
                d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
            </svg>
            Logout
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
<hr>