<table class="container-fluid">
  <dl class="row" style="font-size: larger;">
    <div class="col-8">
      <span><strong>Patient Name : </strong> {{ patient.Name }}</span>
      <br />
      <span
        ><strong>Age/Sex : </strong> {{ patient.Age }} Years/{{ patient.Sex }}
      </span>
      <br />
    </div>
    <div class="col-4 text-right">
      <span><strong>Date : </strong> {{ today }}</span>
    </div>
    <div class="col-12"><strong>Complain : </strong> {{ patient.Complain }}</div>
  </dl>
</table>
