import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-audiologist-dashboard',
  templateUrl: './audiologist-dashboard.component.html',
  styleUrls: ['./audiologist-dashboard.component.css']
})
export class AudiologistDashboardComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Healthy Hearing System")
  }

}
