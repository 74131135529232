import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { SuggestionService } from "../services/suggestion.service";
import { ApiService } from "../services/api.service";
import  { Location} from '@angular/common';

@Component({
  selector: "app-audiogram-report",
  templateUrl: "./audiogram-report.component.html",
  styleUrls: ["./audiogram-report.component.css"],
})
export class AudiogramReportComponent implements OnInit {
  public suggestions: string[] = ["Tamal", "Subhankar", "Tothagata"];

  // three separate line of recommendation
  public recommendationSuggestions: string[] = [];
  public recommendation1Suggestions: string[] = [];
  public recommendation2Suggestions: string[] = [];

  public leftNoteSuggestions: string[] = [];
  public rightNoteSuggestions: string[] = [];
  public weberSuggestions: string[] = [];
  public rinneSuggestions: string[] = [];

  private sub: any;
  private Signs: string = "assets/Signs/";
  public buttonAction: string;

  // three lines of recommendation
  public recommendation: string = "";
  public recommendation1: string = "";
  public recommendation2: string = "";

  public removeHeader: boolean;
  public imageLinks = {
    lac: this.Signs + "lac.png",
    lacm: this.Signs + "lacm.png",
    lbc: this.Signs + "lbc.png",
    lbcm: this.Signs + "lbcm.png",
    rac: this.Signs + "rac.png",
    racm: this.Signs + "racm.png",
    rbc: this.Signs + "rbc.png",
    rbcm: this.Signs + "rbcm.png",
    nr: this.Signs + "NR.JPG",
  };

  public completeData: { [k: string]: any } = {
    leftNote: "",
    rightNote: "",
    rinneNote: "",
    weberNote: "",
  };
  public initData: any;
  rightNote: string = " ";
  leftNote: string = " ";

  patientId: string;
  audiogramId: string;
  action: string;
  DocName: string = "";
  DocDegree: string = "";
  controls: any = {
    pointerType: "ac",
    remove: false,
    noResponse: false,
  };
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private suggestion: SuggestionService,
    private _location: Location
  ) {}
  getSuggestions() {
    this.suggestion.getSuggestions("recommendation").subscribe((data) => {
      this.recommendationSuggestions = data;
    });
    this.suggestion.getSuggestions("recommendation1").subscribe((data) => {
      this.recommendation1Suggestions = data;
    });
    this.suggestion.getSuggestions("recommendation2").subscribe((data) => {
      this.recommendation2Suggestions = data;
    });
    this.suggestion.getSuggestions("leftnote").subscribe((data) => {
      this.leftNoteSuggestions = data;
    });
    this.suggestion.getSuggestions("rightnote").subscribe((data) => {
      this.rightNoteSuggestions = data;
    });
    this.suggestion.getSuggestions("weber").subscribe((data) => {
      this.weberSuggestions = data;
    });
    this.suggestion.getSuggestions("rinne").subscribe((data) => {
      this.rinneSuggestions = data;
    });
  }
  putSuggestions() {
    if (!this.recommendationSuggestions.includes(this.recommendation)) {
      this.recommendationSuggestions.push(this.recommendation);
      this.suggestion.putSuggestions(
        "recommendation",
        this.recommendationSuggestions
      );
    }
    if (!this.recommendation1Suggestions.includes(this.recommendation1)) {
      this.recommendation1Suggestions.push(this.recommendation1);
      this.suggestion.putSuggestions(
        "recommendation1",
        this.recommendation1Suggestions
      );
    }
    if (!this.recommendation2Suggestions.includes(this.recommendation2)) {
      this.recommendation2Suggestions.push(this.recommendation2);
      this.suggestion.putSuggestions(
        "recommendation2",
        this.recommendation2Suggestions
      );
    }
    if (!this.leftNoteSuggestions.includes(this.leftNote)) {
      this.leftNoteSuggestions.push(this.leftNote);
      this.suggestion.putSuggestions("leftnote", this.leftNoteSuggestions);
    }
    if (!this.rightNoteSuggestions.includes(this.rightNote)) {
      this.rightNoteSuggestions.push(this.rightNote);
      this.suggestion.putSuggestions("rightnote", this.rightNoteSuggestions);
    }
    if (!this.weberSuggestions.includes(this.completeData.weberNote)) {
      this.weberSuggestions.push(this.completeData.weberNote);
      this.suggestion.putSuggestions("weber", this.weberSuggestions);
    }
    if (!this.rinneSuggestions.includes(this.completeData.rinneNote)) {
      this.rinneSuggestions.push(this.completeData.rinneNote);
      this.suggestion.putSuggestions("rinne", this.rinneSuggestions);
    }
  }
  printPage() {
    window.print();
  }

  setTitle(title) {
    this.titleService.setTitle(title); //change the document title
  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.patientId = params["patientId"];
      this.audiogramId = params["audiogramId"];
      console.log("the patient id", this.patientId);
      console.log("the audiogram id", this.audiogramId);
      if (this.audiogramId != undefined) {
        this.apiService
          .get("/audiologist/audiogram/" + this.audiogramId)
          .subscribe(
            (data) => {
              console.log("audiogram exists");

              this.initData = data;

              this.leftNote = this.initData.leftNote;
              this.rightNote = this.initData.rightNote;
              // console.log(this.leftNote);
              

              this.completeData = this.initData;
              //handle old database model
              if (this.initData.recommendation.includes("</*>")) {
                let temp = this.initData.recommendation.split("</*>");
                this.recommendation = temp[0];
                this.recommendation1 = temp[1];
                this.recommendation2 = temp[2];
              } else {
                //old data model
                this.recommendation = this.initData.recommendation;
              }

              this.apiService
                .get(
                  "/audiologist/audiologist/" +
                    data.audiologistId
                )
                .subscribe((audData) => {
                  this.DocName = audData.name;
                  this.DocDegree = audData.degree;
                });
            },
            (error) => {
              console.log("audiogram don't exist");
              this.router.navigate(["/audiogram-report/" + this.patientId]);
              this.initData = "none";
            }
          );
      } else {
        this.initData = "none";
        this.apiService
          .get(
            "/audiologist/audiologist/" +
              localStorage.getItem("audiologistId")
          )
          .subscribe((audData) => {
            this.DocName = audData.name;
            this.DocDegree = audData.degree;
          });
      }

      this.action = this.audiogramId == undefined ? "create" : "update";
      this.buttonAction = this.audiogramId == undefined ? "Save" : "Update";
      //console.log(this.action);
    });
    this.getSuggestions();
  }

  //pass control setting from chartControl to charts
  controlUpdate(e) {
    this.controls = e;
    this.completeData["reliability"] = e.reliability;
    this.completeData["validity"] = e.validity;
    this.completeData["testTechnique"] = e.testTechnique;
  }

  save() {
    this.completeData.patientId = this.patientId;
    this.completeData.recommendation =
      this.recommendation +
      "</*>" +
      this.recommendation1 +
      "</*>" +
      this.recommendation2;
    this.completeData.leftNote = this.leftNote;
    this.completeData.rightNote = this.rightNote;
    console.log(this.completeData.weberNote, this.completeData.rinneNote);

    this.completeData.audiologistId = localStorage.getItem("audiologistId");
    this.putSuggestions();

    console.log(this.completeData);
    if (this.action == "create") {
      this.apiService
        .post(
          "/audiologist/audiogram-entry",
          this.completeData
        )
        .subscribe((data) => {
          console.log(data);
          this.router.navigate([
            "/audiogram-report/" + this.patientId + "/" + data._id,
          ]);
        });
    } else if (this.action == "update") {
      this.completeData._id = this.audiogramId;
      //console.log(this.completeData);

      this.apiService
        .post(
          "/audiologist/audiogram-entry/edit",
          this.completeData
        )
        .subscribe((data) => {
          console.log(data);
          this.router.navigate([
            "/audiogram-report/" + this.patientId + "/" + data._id,
          ]);
        });
    }
  }

  audiogramData(e) {
    // let testValue = e[1].split("*")[2].split(",")[1];
    // console.log(e);
    //every event comes as [sourceName, eventData]
    this.completeData[e[0]] = e[1];
  }

  changed(e) {
    console.log("o");

    let name = e.srcElement.name;
    this.audiogramData([
      name,
      name == "leftNote" ? this.leftNote : this.rightNote,
    ]);
    // this.updateParent([])
  }
  backClicked() {
    this._location.back();
  }
}
