<app-header [title]="'Search Patient'"></app-header>
<div class="container-fluid px-4">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <strong>All Patients</strong>
              <button class="btn btn-primary btn-sm pull-right mx-1" routerLink="/new-patient">
                <i class="fa fa-plus"></i> Add New
              </button>
            </div>
          </div>
          <div class="card-body">
            <custom-tableloader></custom-tableloader>
            <custom-tableoption (onFilterChanged)="fetchPatients(1, $event)"></custom-tableoption>

            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th>ID</th>
                        <th>Patient Name</th>
                        <th>Mobile</th>
                        <th class="text-center">Age</th>
                        <th class="text-center">Gender</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="members.length > 0; else noRecordsFound">
                        <tr *ngFor="let item of members; let i = index">
                          <td>
                            {{
                            Global.getPageNumber({
                            index: i,
                            pageno: paginationOptions.page
                            })
                            }}
                          </td>
                          <td>
                            {{ item.id }}
                          </td>
                          <td>
                            {{ item.name }}

                          </td>
                          <td>
                            {{ item.phone }}
                          </td>
                          <td class="text-center">
                            {{ item.age }}

                          </td>
                          <td class="text-center">
                            {{ item.gender }}

                          </td>
                          <td class="text-center">
                            <button routerLink="/audiogram-list/{{item?.id}}" class="mx-2" matTooltip="Audiogram"
                              mat-mini-fab>
                              <mat-icon>analytics</mat-icon>
                            </button>
                            <button color="primary" (click)="edit(item.id)" matTooltip="Edit" mat-mini-fab>
                              <mat-icon>edit</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-template #noRecordsFound>
                        <tr>
                          <td class="text-center" colspan="100%">
                            No records found
                          </td>
                        </tr>
                      </ng-template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <custom-pagination [rows]="members" [paginationOptions]="paginationOptions"
              (onPageClicked)="fetchPatients($event)"></custom-pagination>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>