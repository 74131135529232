import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../services/api.service";
import { SuggestionService } from "../services/suggestion.service";
import * as Global from 'src/app/global';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-patient-panel",
  templateUrl: "./patient-panel.component.html",
  styleUrls: ["./patient-panel.component.css"],
})
export class PatientPanelComponent implements OnInit {
  Global = Global
  public pid: any = null;
  public card: { [k: string]: any } = {};
  public formData: { [k: string]: any } = { name: "" };
  name = "";
  public audiogramBackend: string = "/audiogram-list/";
  public audiogramUrl: string = "";
  public complainSuggestions: string[] = [];
  public addressSuggestions: string[] = [];
  public referred_bySuggestions: string[] = [];
  public patientFormGroup: FormGroup;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private suggestion: SuggestionService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.patientFormGroup = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      gender: [null, Validators.compose([Validators.required])],
      age: [null, Validators.compose([Validators.required])],
      phone: [null, Validators.compose([Validators.required])],
      referred_by: [null, Validators.compose([Validators.required])],
      complain: [null, Validators.compose([Validators.required])],
      address: [null, Validators.compose([Validators.required])],
    })
  }
  getSuggestions() {
    this.spinner.show()
    this.suggestion.getSuggestions("complains").subscribe((data) => {
      this.spinner.hide()
      this.complainSuggestions = data;
    });
    this.suggestion.getSuggestions("refs").subscribe((data) => {
      this.spinner.hide()
      this.referred_bySuggestions = data;
    });
    this.suggestion.getSuggestions("address").subscribe((data) => {
      this.spinner.hide()
      this.addressSuggestions = data ?? [];
    });
  }
  putSuggestions() {
    //submit the complain suggestions
    if (!this.complainSuggestions.includes(this.patientFormGroup?.value?.complain)) {
      this.complainSuggestions.push(this.patientFormGroup?.value?.complain);
      this.suggestion.putSuggestions("complains", this.complainSuggestions);
    }

    //submit the complain suggestions
    if (!this.referred_bySuggestions.includes(this.patientFormGroup?.value?.referred_by)) {
      this.referred_bySuggestions.push(this.patientFormGroup?.value?.referred_by);
      this.suggestion.putSuggestions("refs", this.referred_bySuggestions);
    }
    if (!this.addressSuggestions.includes(this.patientFormGroup?.value?.address)) {
      this.addressSuggestions.push(this.patientFormGroup?.value?.address);
      this.suggestion.putSuggestions("address", this.addressSuggestions);
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      let id = params["patientId"];
      if (id) {
        this.apiService
          .get("/audiologist/patient/" + id)
          .subscribe((data) => {
            this.formData = data;
            this.patientFormGroup.patchValue(this.formData)
            this.card = data;
            this.pid = id;
            this.audiogramUrl = this.audiogramBackend + this.pid;
            console.log(this.audiogramUrl);
          });
      }
    });
    this.getSuggestions();
  }

  submit(event: any) {
    this.patientFormGroup.markAllAsTouched();
    Global.scrollToQuery(".form-control.is-invalid");
    if (
      this.patientFormGroup.valid
    ) {
      this.putSuggestions();
      event.target.classList.add('btn-loading');
      event.target.disabled = true;
      if (this.pid == "Generating") {
        alert("please wait");
      } else if (!this.pid) {
        this.pid = "Generating";
        this.apiService
          .post(
            "/audiologist/patiententry",
            this.patientFormGroup?.value
          )
          .subscribe(
            (data) => {
              event.target.classList.remove('btn-loading');
              event.target.disabled = false;
              this.pid = data.id;
              this.card = data;

              //add patient id to the list url
              this.audiogramUrl += this.pid;
            },
            (err) => {
              event.target.classList.remove('btn-loading');
              event.target.disabled = false;
            }
          );
      } else {
        //update patient
        this.apiService
          .patch(
            "/audiologist/patient/" + this.pid,
            this.patientFormGroup?.value
          )
          .subscribe((data) => {
            // console.log(data.id);
            this.toastr.success("Patient updated successfully")
            this.pid = data.id;
            this.card = data;

            //add patient id to the list url
            this.audiogramUrl += this.pid;
            event.target.classList.remove('btn-loading');
            event.target.disabled = false;
          });
      }
    }
  }
  reset() {
    Global.resetForm(this.patientFormGroup)
  }
}
