import { Component, Injectable } from '@angular/core';

@Injectable()

@Component({
  selector: 'custom-tableloader',
  templateUrl: './custom-tableloader.component.html',
})
export class CustomTableLoaderComponent {
  show() {
    const loaderElement = document.getElementById('table-processing');
    if (loaderElement) {
      loaderElement.style.display = 'block';      
    }
  }

  hide() {
    const loaderElement = document.getElementById('table-processing');
    if (loaderElement) {
      loaderElement.style.display = 'none';
    }
  }
}
