import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddAudiologistComponent } from './add-audiologist/add-audiologist.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AudiogramListComponent } from './audiogram-list/audiogram-list.component';
import { AudiogramReportComponent } from "./audiogram-report/audiogram-report.component";
import { AudiologistDashboardComponent } from './audiologist-dashboard/audiologist-dashboard.component';
import { AudiologistLoginComponent } from './audiologist-login/audiologist-login.component';
import { ManageAudiologistComponent } from './manage-audiologist/manage-audiologist.component';
import { PatientPanelComponent } from './patient-panel/patient-panel.component';
import { SearchPatientComponent } from './search-patient/search-patient.component';

const routes: Routes = [
  {
    path: "",
    component: AudiologistDashboardComponent,
    canActivate:['canActivate']
  },
  {
    path: "new-patient",
    component: PatientPanelComponent,
    canActivate:['canActivate']
  },
  {
    path: "existing-patient/:patientId",
    component: PatientPanelComponent,
    canActivate:['canActivate']
  },
  {
    path: "search-patient",
    component: SearchPatientComponent,
    canActivate:['canActivate']
  },
  {
    path: "login",
    component: AudiologistLoginComponent,
  },
  {
    path: "admin-login",
    component: AdminLoginComponent,
  },
  {
    path: "admin",
    component: AdminDashboardComponent,
    canActivate:['canActivate']
  },
  {
    path: "admin/manage-audiologists",
    component: ManageAudiologistComponent,
    canActivate:['canActivate']
  },
  {
    path: "admin/new-audiologist",
    component: AddAudiologistComponent,
    canActivate:['canActivate']
  },
  {
    path: "admin/existing-audiologist/:id",
    component: AddAudiologistComponent,
    canActivate:['canActivate']
  },
  {
    path: "audiogram-report/:patientId/:audiogramId",
    component: AudiogramReportComponent,
    data: { title: "Audiogram Report" },
    canActivate:['canActivate']
  },
  {
    path: "audiogram-list/:patientId",
    component: AudiogramListComponent,
    canActivate:['canActivate']
  },
  {
    path: "audiogram-report/:patientId",
    component: AudiogramReportComponent,
    data: { title: "Audiogram Report" },
    canActivate:['canActivate']
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: "canActivate",
      useValue: (route, state)=>{
        if(localStorage.getItem("token")!==null){
          return true;
        } else {
          window.location.href = "/login";
          return false;
        }
      }
    },
  ]
})
export class AppRoutingModule {}
