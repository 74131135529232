<div class="text-right">
  <app-header [title]="'Healthy Hearing System'"></app-header>

  <div class="container">
    <table style="width: 80%; text-align: center; margin-left: 200px">
      <tr>
        <td>
          <div class="card" style="width: 18rem;">
            <svg width="1em" height="1em" style="font-size: 8rem;" viewBox="0 0 16 16" class="bi bi-file-earmark-arrow-up mx-auto mt-3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z"/>
                <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V7.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 7.707V11.5a.5.5 0 0 0 .5.5z"/>
              </svg>
            <div class="card-body">
              <h5 class="card-title">Add New Patient</h5>
              <button
                type="button"
                class="btn btn-large btn-info"
                [routerLink]="['/new-patient']"
              >
                <span class="glyphicon glyphicon-upload"></span> New Patient
              </button>
            </div>
          </div>
        </td>
        <td>
          <div class="card" style="width: 18rem">
            <svg width="1em" height="1em" style="font-size: 8rem;" viewBox="0 0 16 16" class="bi bi-card-list mx-auto mt-3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                <path fill-rule="evenodd" d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"/>
                <circle cx="3.5" cy="5.5" r=".5"/>
                <circle cx="3.5" cy="8" r=".5"/>
                <circle cx="3.5" cy="10.5" r=".5"/>
              </svg>
            <div class="card-body">
              <h5 class="card-title">For Existing Patient</h5>
              <!--p class="card-text">Some quick example text to build on the card title and make up the bulk of the card content.</p-->
              <button
                type="button"
                class="btn btn-large btn-info"
                [routerLink]="['/search-patient']"
              >
                <span class="glyphicon glyphicon-list-alt"></span> Existing
                Patient
              </button>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
