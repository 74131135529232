<div class="wrapper fadeInDown">
    <div id="formContent">
      <!-- Tabs Titles -->
  
      <!-- Icon -->
      <div class="fadeIn first m-5">
        <img src="assets/hh_logo.png" id="icon" alt="User Icon" />
      </div>
  
      <!-- Login Form -->
      <form>
        <input
          type="text"
          id="login"
          class="fadeIn second"
          name="login"
          [(ngModel)] = "data.username"
          placeholder="Admin Id"
        />
        <input
          type="password"
          id="password"
          class="fadeIn third"
          name="pass"
          [(ngModel)] = "data.password"
          placeholder="Password"
        />
        <input (click)="submit()" type="submit" class="fadeIn fourth" value="Log In" />
      </form>
  
      <!-- Remind Passowrd -->
      <div id="formFooter">
        <a class="underlineHover" href="login">Audiologist Login</a>
      </div>
    </div>
  </div>
  