import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as Global from 'src/app/global';

@Injectable({
  providedIn: "root",
})
export class ApiService {

  backend: string = "";
  constructor(private http: HttpClient) {
      this.backend = Global.API_URL;
      // this.backend = "http://localhost:5000";
  }
  
  test() {
    console.log("service is working");
  }

  //get call
  get(route: string){
    return this.http.get<any>(
        this.backend+route
      );
  }

  post(route: string, data: { [k: string]: any }){
    // console.log('I ran');
    
    return this.http.post<any>(
        this.backend+route, data
      );
  }

  patch(route: string, data: { [k: string]: any }){
    return this.http.patch<any>(
        this.backend+route, data
      );
  }

  put(route: string, data: { [k: string]: any }){
    return this.http.put<any>(
        this.backend+route, data
      );
  }
}
