<div>
  <app-header [title]="'Audiologist Management'"></app-header>
  <div class="ml-5 mt-5">
    <label for="docName" style="font-size: 1.2rem; font-weight: 500"
      >Audiologist's name</label
    >
    <input type="text" id="docName" class="py-1 mx-2" [(ngModel)]="searchTerm" />
    <button class="btn btn-lg btn-success m-2 py-1" (click)="search()">Search</button>
  <button class="btn btn-lg btn-success py-1" style="position: absolute;right:0;margin-right: 10rem;" onClick="window.open('admin/new-audiologist')">Add Audiologist</button>

  </div>

  <hr />
  <div class="container">
    <!-- sort by -->
    <!-- <div class="text-right">
      <label for="sortBy" style="font-weight: 500">Sort by</label>
      <select id="sortBy" class="mx-4">
        <option value="1">Name (Ascending)</option>
        <option value="2">Name (Descending)</option>
        <option value="7">username (Ascending)</option>
        <option value="8">Username (Descending)</option>
        <option value="3">Last Month (Ascending)</option>
        <option value="4">Last Month (Descending)</option>
        <option value="5">This Month (Ascending)</option>
        <option value="6">This Month (Descending)</option>
      </select>
    </div> -->

    <!-- search results -->
    <table class="table">
      <thead style="background-color: rgb(230, 230, 230)">
        <tr>
          <th scope="col">Username</th>

          <th scope="col">Name</th>
          <th scope="col">Degree</th>
          <th scope="col">Phone</th>
          <th scope="col">email</th>


          <!-- <th scope="col">Last Month</th> -->
          <!-- <th scope="col">This Month</th> -->
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let audiologist of audiologists">
          <td scope="col">{{ audiologist.username }}</td>
          <td scope="col">{{ audiologist.name }}</td>
          <td scope="col">{{ audiologist.degree }}</td>
          <td scope="col">{{ audiologist.phone }}</td>
          <td scope="col">{{ audiologist.email }}</td>

          <!-- <td scope="col">{{ audiologist.lastMonth }}</td> -->
          <!-- <td scope="col">{{ audiologist.thisMonth }}</td> -->
          <td scope="col"><button class="btn btn-sm btn-danger" (click)="edit(audiologist._id)">Edit</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
