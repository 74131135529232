import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import  { Location} from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
@Input() title: string;
  constructor(private titleService: Title , private _location: Location) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  
  }
logout(){
  localStorage.removeItem("token");
  window.location.href = "/login"
}
backClicked() {
  this._location.back();
}
}
