import { Component, Injectable, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as Global from 'src/app/global';

@Injectable()

@Component({
  selector: 'custom-tableoption',
  templateUrl: './custom-tableoption.component.html',
})
export class CustomTableOptionComponent {
  filterForm: FormGroup;
  limitOptions: any[] = Global.TABLE_LENGTH_OPTIONS;
  @Output() onFilterChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.filterForm = formBuilder.group({
      searchkey: [null],      
      limit: [Global.TABLE_LENGTH],      
    });
    //console.log(this.limitOptions);
  }

}
