import { Component, OnInit } from "@angular/core";
import { ApiService } from "../services/api.service";
@Component({
  selector: "app-manage-audiologist",
  templateUrl: "./manage-audiologist.component.html",
  styleUrls: ["./manage-audiologist.component.css"],
})
export class ManageAudiologistComponent implements OnInit {
  public audiologists: {
    username: string;
    name: string;
    lastMonth: number;
    thisMonth: number;
    id: string;
  }[];
  public searchTerm: string = "";

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.search();
  }

  search() {
    //fetch audiologist data
    if (this.searchTerm) {
      this.apiService
        .get(
          "/admin/searchaudiologist/" + this.searchTerm
        )
        .subscribe((data) => {
          this.audiologists = data;
        });
    } else {
      this.apiService
        .get("/admin/allaudiologists")
        .subscribe((data) => {
          this.audiologists = data;
          console.log(data);
        });
    }
  }

  edit(id: string) {
    //get audiologistId
    // console.log(id);
    /* this.http.get<any>("http://65.1.50.244:5000/admin/delete/"+id).subscribe(data=>{
      console.log(data);
      
    }); */

    let confirmation = confirm("Are you sure ?");
    if (confirmation) {
      window.open("/admin/existing-audiologist/" + id);
    }
  }
}
