import { Component, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "../services/api.service";
import * as Global from 'src/app/global';
import PaginationOptions from "../models/PaginationOptions";
import TableFilterOptions from "../models/TableFiilterOptions";
import { CustomTableLoaderComponent } from "../includes/custom-tableloader/custom-tableloader.component";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import Swal from 'sweetalert2'

@Component({
  selector: "app-search-patient",
  templateUrl: "./search-patient.component.html",
  styleUrls: ["./search-patient.component.css"],
})
export class SearchPatientComponent implements OnInit {
  Global = Global
  selectedOption: any;
  public patients: { [k: string]: any }[] = [];
  public sortedbyname: { [k: string]: any }[] = [];
  public auxiliary: { [k: string]: any }[] = [];
  public id: string = "";
  public name: string = "";
  public phone: string = "";
  paginationOptions: PaginationOptions;
  members: any[] = [];

  constructor(private titleService: Title,
    private apiService: ApiService,
    private toastr: ToastrService,
    private tableLoader: CustomTableLoaderComponent,
    private router: Router,
    private spinner: NgxSpinnerService) {
    this.paginationOptions = Global.resetPaginationOption();
  }

  ngOnInit(): void {

    this.fetchPatients();
    // this.auxiliary=this.patients;
    // console.log(this.auxiliary);
  }

  checkpat() {
    console.log("check");
    let isValid: boolean =
      [this.id, this.name, this.phone].filter((v) => v.length).length == 1;
    if (isValid) {
      this.spinner.show();
      if (this.id) {
        this.apiService.get("/audiologist/listbyid/" + this.id).subscribe(
          data => {
            this.patients = data;
            // this.auxiliary = data; 
            // console.log(this.auxiliary)
            // console.log(data.length);
            this.auxiliary.length = 0;
            this.patients.forEach(val => this.auxiliary.push(Object.assign({}, val)));

            // for(var i=0; i<data.length; i++)
            //    {this.patients[i].created=this.patients[i].id.match(/\d/g).join("");
            //   // console.log(this.patients[i]);
            //    }
            this.spinner.hide();
          }
        )
      }
      if (this.name) {
        this.apiService.get("/audiologist/listbyname/" + this.name).subscribe(
          data => {
            this.patients = data;
            // this.sortbyname();
            // this.auxiliary = data;
            this.auxiliary.length = 0;
            this.patients.forEach(val => this.auxiliary.push(Object.assign({}, val)));
            // console.log(data.length);
            // console.log(this.auxiliary)

            // for(var i=0; i<data.length; i++)
            //    {this.patients[i].created=this.patients[i].id.match(/\d/g).join("");
            //   // console.log(this.patients[i]);
            //    }
            this.spinner.hide();

          }
        )
      }
      if (this.phone) {
        this.spinner.hide();

      }
    } else {
      alert("only one field allowed");
    }
  }

  edit(id: string) {
    // let conf = confirm("Are you sure?")
    // if (conf)
    //   window.open("/existing-patient/" + id)
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to update patient details!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl("/existing-patient/" + id)
      }
    })
  }

  sortbyname() {
    // console.log(this.patients) ;  
    this.sortedbyname = this.patients;
    this.sortedbyname.sort((a, b) => a.name.localeCompare(b.name));
    console.log(this.sortedbyname);
  }

  onOptionSelect(val: any) {

    var x = parseInt(val);

    switch (x) {
      case 1:
        // name ascending
        this.patients.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 2:
        // name descending
        {
          //  this.patients.sort((one, two) => (one > two ? -1 : 1));
          this.patients.sort((a, b) => a.name.localeCompare(b.name));
          this.patients.reverse();

          break;
        }
      case 3:
        // id ascending
        this.patients.sort((a, b) => a.id.localeCompare(b.id));
        break;
      case 4:
        // id descending
        {
          this.patients.sort((a, b) => a.id.localeCompare(b.id));
          this.patients.reverse();
          break;
        }
      case 5:
        // age ascending

        this.patients = this.patients.sort(function (a, b) {
          return a.age > b.age ? 1 : a.age < b.age ? -1 : 0
        })
        break;
      case 6:
        // age descending
        {
          this.patients = this.patients.sort(function (a, b) {
            return a.age > b.age ? 1 : a.age < b.age ? -1 : 0
          })

          this.patients.reverse();
          break;
        }

      case 7:
        // previously

        {


          // this.patients = this.patients.sort(function(a,b){
          //   return a.created >b.created?1:a.created <b.created?-1:0
          //  })


          //  this.patients.sort((a, b) => (a.created < b.created ? -1 : 1));

          this.patients.length = 0;
          this.auxiliary.forEach(val => this.patients.push(Object.assign({}, val)));



          break;
        }

      case 8:
        // recently

        {

          // this.patients = this.patients.sort(function(a,b){
          //   return a.created >b.created?1:a.created <b.created?-1:0
          //  })


          this.patients.length = 0;
          this.auxiliary.forEach(val => this.patients.push(Object.assign({}, val)));

          this.patients.reverse();
          break;
        }

      default:
        console.log("No such thing exists!");
        break;
    }
  }

  fetchPatients(
    page: any = null,
    options: TableFilterOptions = Global.resetTableFilterOptions()
  ) {
    if (page != null) {
      this.paginationOptions.page = page;
    }

    this.tableLoader.show();
    this.apiService
      .post("/audiologist/patient/list", {
        page_no: this.paginationOptions.page,
        searchkey: options.searchkey ? options.searchkey : '',
        length: Number(options.limit),
      })
      .subscribe(
        (res) => {
          if (res.status == 'success') {
            const data = res.data
            this.members = data?.docs ?? [];
            this.paginationOptions = {
              hasNextPage: data.hasNextPage,
              hasPrevPage: data.hasPrevPage,
              limit: data.limit,
              nextPage: data.nextPage,
              page: data.page,
              pagingCounter: data.pagingCounter,
              prevPage: data.prevPage,
              totalDocs: data.totalDocs,
              totalPages: data.totalPages,
            };


          } else {
            this.toastr.error(res.message);

            this.members = [];
            this.paginationOptions = Global.resetPaginationOption();
          }
          this.tableLoader.hide();
        },
        (err) => {
          this.members = [];
          this.tableLoader.hide();
          // this.errorService.showError(err);
          this.paginationOptions = Global.resetPaginationOption();
        }
      );

  }
}
// myArray.forEach(val => myClonedArray.push(Object.assign({}, val)));
