import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ChartsModule } from "ng2-charts";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AudiogramReportComponent } from "./audiogram-report/audiogram-report.component";
import { PatientDetailsComponent } from "./audiogram-report/patient-details/patient-details.component";
import { ChartsComponent } from "./audiogram-report/charts/charts.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ChartControlComponent } from "./audiogram-report/chart-control/chart-control.component";
import { AudiometricComponent } from "./audiogram-report/audiometric/audiometric.component";
import { OptionsComponent } from "./audiogram-report/audiometric/options/options.component";
import { AudiologistLoginComponent } from './audiologist-login/audiologist-login.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AudiologistDashboardComponent } from './audiologist-dashboard/audiologist-dashboard.component';
import { PatientPanelComponent } from './patient-panel/patient-panel.component';
import { SearchPatientComponent } from './search-patient/search-patient.component';
import { HeaderComponent } from './header/header.component';
import { ManageAudiologistComponent } from './manage-audiologist/manage-audiologist.component';
import { AddAudiologistComponent } from './add-audiologist/add-audiologist.component';
import { AudiogramListComponent } from './audiogram-list/audiogram-list.component';
import {
  TokenInterceptor
} from './services/token.service';
import { Ng2CompleterModule } from 'ng2-completer';
import { SuggestionService } from './services/suggestion.service';
import { NgxSpinnerModule } from "ngx-spinner";

import { ApiService } from "./services/api.service";
import { ToastrModule } from 'ngx-toastr';
import { CustomPaginationComponent } from "./includes/custom-pagination/custom-pagination.component";
import { CustomTableLoaderComponent } from "./includes/custom-tableloader/custom-tableloader.component";
import { CustomTableOptionComponent } from "./includes/custom-tableoption/custom-tableoption.component";
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    AudiogramReportComponent,
    PatientDetailsComponent,
    ChartsComponent,
    ChartControlComponent,
    AudiometricComponent,
    OptionsComponent,
    AudiologistLoginComponent,
    AdminLoginComponent,
    AdminDashboardComponent,
    AudiologistDashboardComponent,
    PatientPanelComponent,
    SearchPatientComponent,
    HeaderComponent,
    ManageAudiologistComponent,
    AddAudiologistComponent,
    AudiogramListComponent,
    CustomPaginationComponent,
    CustomTableLoaderComponent,
    CustomTableOptionComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    Ng2CompleterModule,
    NgxSpinnerModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    MatTooltipModule
  ],
  providers: [Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    SuggestionService,
    ApiService,
    CustomTableLoaderComponent

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
