import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";

@Component({
  selector: "app-options",
  templateUrl: "./options.component.html",
  styleUrls: ["./options.component.css"],
})
export class OptionsComponent implements OnInit {
  @ViewChild("select") select: ElementRef;
  @Input() id;
  @Output() dataEvent = new EventEmitter<string[]>();
  blankImg: string =
    "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
  returnValue: string[] = Array(5).fill("");
  //there is blank element to get triggered on first option
  options: string[];
  rinneOptions: string[] = ["", "+VE+", "-VE-", "+VE-", "-VE+"];
  weberOptions: string[] = ["", "Right", "Up", "Left"];
  imgSrc: any = {
    l_pos: "assets/Signs/lp.png",
    l_neg: "assets/Signs/ln.png",
    r_pos: "assets/Signs/rp.png",
    r_neg: "assets/Signs/rn.png",
    right: "assets/Signs/ra.png",
    up: "assets/Signs/ua.png",
    left: "assets/Signs/la.png",
  };
  frequencies: string[] = ["250Hz", "500Hz", "1KHz", "2KHz", "4KHz"];
  //generate frequencyValue iterable of objects from frequency labels
  frequencyValues: any = Array(this.frequencies.length)
    .fill("")
    .map((_, index) => {
      return {
        name: this.frequencies[index],
        right: this.blankImg,
        left: this.blankImg,
      };
    });

  @Input() initData: any;

  constructor() {}

  ngOnInit(): void {
    /* if (this.initData != "none") {
      console.log(this.initData);
    } */

    // console.log(this.frequencyValues);
    this.options = this.id == "rinne" ? this.rinneOptions : this.weberOptions;
  }

  selected(e) {
    let elementName = e.srcElement.name;
    let selectedValue = e.target.value;
    // console.log(elementName, selectedValue);
    this.updateParent();

    this.changeData(this.frequencies.indexOf(elementName), selectedValue);
  }

  changeData(frequencyIndex, selectedValue) {
    this.returnValue[frequencyIndex] = selectedValue;

    //select images according to data
    if (selectedValue != "") {
      if (this.id == "rinne") {
        this.frequencyValues[frequencyIndex].left =
          selectedValue[3] == "-" ? this.imgSrc.l_neg : this.imgSrc.l_pos;
        this.frequencyValues[frequencyIndex].right =
          selectedValue[0] == "-" ? this.imgSrc.r_neg : this.imgSrc.r_pos;
      } else if (this.id == "weber") {
        if (selectedValue == "Left") {
          this.frequencyValues[frequencyIndex].left = this.imgSrc.right;
          this.frequencyValues[frequencyIndex].right = this.blankImg;
        } else {
          this.frequencyValues[frequencyIndex].right =
            selectedValue == "Up" ? this.imgSrc.up : this.imgSrc.left;
          this.frequencyValues[frequencyIndex].left = this.blankImg;
        }
      }
    } else {
      this.frequencyValues[frequencyIndex].left = this.blankImg;
      this.frequencyValues[frequencyIndex].right = this.blankImg;
    }
    this.updateParent();
  }

  updateParent() {
    
    let t = this.returnValue.join(",");
    this.dataEvent.emit([this.id, t]);
  }

  ngAfterViewInit() {
    try{
    for (let [i, data] of this.initData[this.id].split(",").entries()) {
      // console.log(data);
      
      this.changeData(i, data);
    }
    this.updateParent();
  } catch (e){
    
  }
  }
}
