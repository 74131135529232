<div>
  <app-header [title]="'Admin portal'"></app-header>
  <div class="m-5">
    <label for="docName" style="font-size: 1.2rem; font-weight: 500"
      >Doctor's name</label
    >
    <input type="text" id="docName" class="py-1 mx-2" [(ngModel)]="docName" />
    <button class="btn btn-lg btn-success m-2 py-1" (click)="fetchData()">Search</button>
    <a class="btn btn-md btn-info float-right" href="/admin/manage-audiologists">Manage Audiologists</a>
    
  </div>
  <hr />
  <div class="container">
    <!-- sort by -->
    <!-- <div class="text-right">
    <label for="sortBy" style="font-weight: 500;">Sort by</label>
    <select id="sortBy" class="mx-4">
      <option value="1">Name (Ascending)</option>
      <option value="2">Name (Descending)</option>
      
      <option value="3">Last Month (Ascending)</option>
      <option value="4">Last Month (Descending)</option>
      <option value="5">This Month (Ascending)</option>
      <option value="6">This Month (Descending)</option>


    </select>
  </div> -->

  <!-- search results -->
    <table class="table">
      <thead style="background-color: rgb(230, 230, 230)">
        <tr>
          <th scope="col">Doctor's Name</th>
          <th scope="col">Last Month</th>
          <th scope="col">This Month</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let doc of docStat">
          <td scope="col">{{doc.name}}</td>
          <td scope="col">{{doc.count[currentMonth-1]}}</td>
          <td scope="col">{{doc.count[currentMonth]}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
