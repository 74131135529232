<div>
  <app-header [title]="'Audiologist Form'"></app-header>
  <div class="container">
    <form class="mt-5">
      <div class="row">
        <label for="username" class="font-weight-normal col-2">Username</label>
        <input class="col-2" type="text" name="username" placeholder="Username" [(ngModel)]="formData.username"/>
      </div>

      <div class="row mt-3">
        <label for="name" class="font-weight-normal col-2">Fullname</label>
        <input class="col-2" type="text" name="name" placeholder="Full name" [(ngModel)]="formData.name"/>
      </div>

      <div class="row mt-3">
        <label for="degree" class="font-weight-normal col-2">Degree</label>
        <input class="col-2" type="text" name="degree" placeholder="Degree" [(ngModel)]="formData.degree"/>
      </div>
      <div class="row mt-3">
        <label for="phone" class="font-weight-normal col-2">Phone Number</label>
        <input class="col-2" type="tel" name="phone" placeholder="Phone Number" [(ngModel)]="formData.phone"/>
      </div>
      <div class="row mt-3">
        <label for="email" class="font-weight-normal col-2">Email</label>
        <input
          class="col-2"
          type="email"
          name="email"
          placeholder="Email address"
          [(ngModel)]="formData.email"
        />
      </div>
      <div class="row mt-3">
        <label for="password" class="font-weight-normal col-2">Password</label>
        <input
          class="col-2"
          type="password"
          name="password"
          placeholder="Password"
          [(ngModel)]="formData.password"
        />
      </div>
      <div class="row mt-3">
        <label for="confirm_password" class="font-weight-normal col-2"
          >Confirm Password</label
        >
        <input
          class="col-2"
          type="password"
          name="confirm_password"
          placeholder="Confirm Password"
          [(ngModel)]="formData.confirm_password"

        />
      </div>
      <br />
      <button class="btn btn-lg btn-success" (click)="submit()">Submit</button>
    </form>
  </div>
</div>
