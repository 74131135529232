import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.css"],
})
export class AdminDashboardComponent implements OnInit {
  constructor(private titleService: Title, private apiService: ApiService) {}
  public docStat: { name: string; lastMonth: number; thisMonth: number }[];
  public docName: string = "";
  public currentMonth: number;
  public lastMonth: number;

  ngOnInit(): void {
    this.titleService.setTitle("Admin");
    this.currentMonth = new Date().getMonth();
    if (this.currentMonth == 0) {
      this.lastMonth = 11;
    } else {
      this.lastMonth = this.currentMonth - 1;
    }
    this.fetchData();
  }

  fetchData(e = null) {
    // console.log(e);
    console.log(this.docName);

    if (this.docName) {
      //api call for particular name
    } else {
      //api call to get full list
      this.apiService
        .get("/admin/allrefs")
        .subscribe((data) => {
          this.docStat = data;
        });
    }
  }
}
