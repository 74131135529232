<label class="btn btn-default active">
  <input
    type="radio"
    name="pointer"
    [(ngModel)]="model.pointerType"
    (ngModelChange)="updateControl()"
    value="ac"
    checked="checked"
  />
  AC
</label>
<br />
<label class="btn btn-default">
  <input
    type="radio"
    name="pointer"
    [(ngModel)]="model.pointerType"
    (ngModelChange)="updateControl()"
    value="ac_masked"
  />
  AC Masked
</label>
<br />
<label class="btn btn-default active">
  <input
    type="radio"
    name="pointer"
    [(ngModel)]="model.pointerType"
    (ngModelChange)="updateControl()"
    value="bc"
  />
  BC
</label>
<br />
<label class="btn btn-default">
  <input
    type="radio"
    name="pointer"
    [(ngModel)]="model.pointerType"
    (ngModelChange)="updateControl()"
    value="bc_masked"
  />
  BC Masked
</label>
<br />
<label class="btn btn-default">
  <input
    type="radio"
    name="pointer"
    [(ngModel)]="model.pointerType"
    (ngModelChange)="updateControl()"
    value="sf"
  />
  Sound Field
</label>
<br />
<label class="btn btn-default">
  <input
    type="radio"
    name="pointer"
    [(ngModel)]="model.pointerType"
    (ngModelChange)="updateControl()"
    value="a"
  />
  Aided
</label>
<br />
<div class="custom-control custom-switch mt-4 ml-2">
  <input
    type="checkbox"
    class="custom-control-input"
    id="removePoints"
    [(ngModel)]="model.remove"
    (ngModelChange)="updateControl()"
  />
  <label class="custom-control-label hoverHand" for="removePoints"
    >Remove</label
  >
</div>
<hr />
<div class="custom-control custom-switch mt-4 ml-2">
  <input
    type="checkbox"
    class="custom-control-input"
    id="noResponse"
    [(ngModel)]="model.noResponse"
    (ngModelChange)="updateControl()"
  />
  <label class="custom-control-label hoverHand" for="noResponse"
    >No Response</label
  >
  <br />
  <img src="assets/Signs/NR.JPG" alt="" />
</div>
<hr class="m-2"/>
<div>
  <div>
    <label for="testTechnique">Test Technique</label>

    <select
      name="testTechnique"
      id=""
      class="hoverHand"
      [(ngModel)]="model.testTechnique"
      (ngModelChange)="updateControl()"
    >
      <option value="Conventional" selected>Conventional</option>
      <option value="Play Audiometry">Play Audiometry</option>
      <option value="VRA" selected>VRA</option>
    </select>
    <br />
    <label for="Reliability">Reliability</label>

    <select
      name="Raliability"
      id=""
      class="hoverHand"
      [(ngModel)]="model.reliability"
      (ngModelChange)="updateControl()"
    >
      <option value="Good" selected>Good</option>
      <option value="Fair">Fair</option>
      <option value="Poor">Poor</option>
    </select>
    <br />
    <label for="Validity">Validity</label>

    <select
      name="Validity"
      id=""
      class="hoverHand"
      [(ngModel)]="model.validity"
      (ngModelChange)="updateControl()"
    >
      <option value="Acceptable" selected>Acceptable</option>
      <option value="Questionable">Questionable</option>
    </select>
  </div>
</div>
