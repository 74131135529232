<tr class="d-flex">
  <h6 class="text-left col red-text" style="font-weight: bold">RIGHT</h6>
  <h6 class="text-right col blue-text" style="font-weight: bold">LEFT</h6>
</tr>
<tr class="d-flex" *ngFor="let fv of frequencyValues">
  <img height="15px" width="28px" class="my-auto mx-4" src="{{ fv.right }}" />
  <div class="mx-auto">
    <p>{{ fv.name }}</p>
    <select #select name="{{ fv.name }}" (change)="selected($event)" class="no-print">
      <option value="{{ op }}" *ngFor="let op of options">{{ op }}</option>
    </select>
  </div>
  <img height="15px" width="28px" class="my-auto mx-4" src="{{ fv.left }}" />
</tr>
