import { registerLocaleData } from "@angular/common";
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { ChartDataSets, ChartOptions, ChartPoint, Chart } from "chart.js";
import { BaseChartDirective, Color, Label, ChartsModule } from "ng2-charts";

@Component({
  selector: "app-charts",
  templateUrl: "./charts.component.html",
  styleUrls: ["./charts.component.css"],
})
export class ChartsComponent implements OnInit {
  @Input() maskId: string;
  @Input() controls: any;
  @Input() initData: any;
  @Output() dataEvent = new EventEmitter<string[]>();

  //Canvas dimensions
   chartHeight: number = 500;
   chartWidth: number = 500;
   chartRightPadding: number = 40; //needed to maintain border distance

  inline: Inline = new Inline();
  private backData: string = "";
  legendImgSrc: string;
  textClass: string;
  // public Chart = Chart && Chart.hasOwnProperty('default') ? Chart['default'] : Chart;

  private pointerImages: { [k: string]: any } = {};
  private lineColor: string = "blue";
  private initDataset = [];
  mouseLoc = " ";
  borderDash: number[] = [2, 4];
  pointerRadius: number = 10;
  PTA1: String = "NaN";
  PTA2: string = "NaN";
  gridColor = Array(17)
    .fill("")
    .map((k, i) => {
      return [2, 4].includes(i)
        ? "rgba(0,0,0,0.0)"
        : i % 2
        ? "rgba(0,0,0,0.5)"
        : "rgba(0,0,0,0.1)";
    });
  datasetKeys = {
    ac: 0,
    bc: 1,
    sf: 2,
    a: 3,
    no_ac: 4,
    no_bc: 5,
    no_sf: 6,
    no_a: 7,
  };
  @ViewChild(BaseChartDirective) chart: BaseChartDirective; //get access to the the chart object

  /*-------chart settings---------*/
  public lineChartData: any;
  public lineChartLabels: Label[];
  public lineChartOptions: any;
  public lineChartColors: Color[];
  public lineChartLegend = true;
  public lineChartType = "line";
  public lineChartPlugins = [];
  /*-------end of chart settings---------*/

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  //chart variable assignments
  chartInit() {
    var Signs = "assets/Signs/";
    this.legendImgSrc = "assets/Signs/" + this.maskId + "_legends.png";
    var imageLinks = [
      Signs + this.maskId[0] + "ac.png",
      Signs + this.maskId[0] + "acm.png",
      Signs + this.maskId[0] + "bc.png",
      Signs + this.maskId[0] + "bcm.png",
      Signs + "no_" + this.maskId[0] + "ac.png",
      Signs + "no_" + this.maskId[0] + "acm.png",
      Signs + "no_" + this.maskId[0] + "bc.png",
      Signs + "no_" + this.maskId[0] + "bcm.png",
      Signs + this.maskId[0] + "_s.png",
      Signs + this.maskId[0] + "_a.png",
      Signs + "no_" + this.maskId[0] + "_s.png",
      Signs + "no_" + this.maskId[0] + "_a.png",
    ];

    let pointerNames: string[] = [
      "ac",
      "ac_masked",
      "bc",
      "bc_masked",
      "no_ac",
      "no_ac_masked",
      "no_bc",
      "no_bc_masked",
      "sf",
      "a",
      "no_sf",
      "no_a",
    ];

    pointerNames.forEach((value, index) => {
      let item = new Image();
      item.src = imageLinks[index];
      this.pointerImages[value] = item;
    });

    this.lineColor = this.maskId == "left" ? "blue" : "red";
    this.lineChartData = [
      {
        data: Array(17).fill(null),
        label: "AC",
        pointHoverRadius: this.pointerRadius,
        lineTension: 0,
        pointStyle: Array(17).fill(null),
        colors: Array(17).fill(this.lineColor),
      },

      {
        data: Array(17).fill(null),
        label: "BC",
        pointHoverRadius: this.pointerRadius,
        lineTension: 0,
        pointStyle: Array(17).fill(null),
        borderDash: this.borderDash,
        colors: Array(17).fill(this.lineColor),
      },
      {
        data: Array(17).fill(null),
        label: "Sound Field",
        pointHoverRadius: this.pointerRadius,
        lineTension: 0,
        pointStyle: Array(17).fill(null),
        colors: Array(17).fill(this.lineColor),
      },
      {
        data: Array(17).fill(null),
        label: "Aided",
        pointHoverRadius: this.pointerRadius,
        lineTension: 0,
        pointStyle: Array(17).fill(null),
        colors: Array(17).fill(this.lineColor),
      },
      {
        data: Array(17).fill(null),
        label: "No AC",
        pointHoverRadius: this.pointerRadius,
        lineTension: 0,
        pointStyle: Array(17).fill(null),
        showLine: false,
      },

      {
        data: Array(17).fill(null),
        label: "No BC",
        pointHoverRadius: this.pointerRadius,
        lineTension: 0,
        pointStyle: Array(17).fill(null),
        showLine: false,
      },
      {
        data: Array(17).fill(null),
        label: "No Sound Field",
        pointHoverRadius: this.pointerRadius,
        lineTension: 0,
        pointStyle: Array(17).fill(null),
        showLine: false,
      },
      {
        data: Array(17).fill(null),
        label: "No Aided",
        pointHoverRadius: this.pointerRadius,
        lineTension: 0,
        pointStyle: Array(17).fill(null),
        showLine: false,
      },
    ];

    this.lineChartLabels = [
      "",
      "125",
      "",
      "250",
      "",
      "500",
      "750",
      "1K",
      "1.5K",
      "2K",
      "3K",
      "4K",
      "6K",
      "8K",
      "10K",
      "12K",
      "16K",
    ];
    this.lineChartOptions = {
      //drawing options
      // (ChartOptions & { annotation: any })
      responsive: false, //fixed canvas size
      spanGaps: true, //draw line without considering null points
      legend: {
        display: false,
      },
      layout: { padding: {
        right: this.chartRightPadding
      } },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: "rgba(0,0,0,0.5)",
              lineWidth: 1,
              zeroLineWidth: 1,
            },
            ticks: {
              reverse: true,
              stepSize: 10,
              max: 120,
              min: -10,
            },
            scaleLabel: {
              display: false,
              labelString: "Heading Level (dB)",
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              lineWidth: 2,
              color: this.gridColor, //alternating grid line width
            },
            scaleLabel: {
              display: true,
              labelString: "Frequency(Hz)",
            },
          },
        ],
      },
    };
    this.lineChartColors = [
      {
        backgroundColor: "rgba(255,0,0,0.0)",
      },
      {
        backgroundColor: "rgba(255,0,0,0.0)",
      },
      {
        backgroundColor: "rgba(255,0,0,0.0)",
      },
      {
        backgroundColor: "rgba(255,0,0,0.0)",
      },
    ];
    this.lineChartPlugins = [
      {
        afterLayout: (chart) => {
          var dataWithLine = 4;
          for (let j = 0; j < dataWithLine; j++) {
            var ctx = chart.chart.ctx;
            var xAxis = chart.scales["x-axis-0"];
            var gradientStroke = ctx.createLinearGradient(
              xAxis.left,
              0,
              xAxis.right,
              0
            );

            var dataset = chart.data.datasets[j];
            // console.log(dataset);

            dataset.colors.forEach((c, i) => {
              var stop = (1 / (dataset.colors.length - 1)) * i;
              var prevColor = i == 0 ? "rgba(0,0,0,0)" : dataset.colors[i - 1];
              gradientStroke.addColorStop(stop, prevColor);
              gradientStroke.addColorStop(stop, dataset.colors[i]);
            });

            dataset.borderColor = gradientStroke;
            dataset.pointBorderColor = gradientStroke;
            dataset.pointBackgroundColor = gradientStroke;
            dataset.pointHoverBorderColor = gradientStroke;
            dataset.pointHoverBackgroundColor = gradientStroke;
          }
        },
      },
    ];
    if (this.initData != "none") {
      this.restoreData();
    }
    this.calculatePTA();
  }

  toggleControlType(type: string) {
    if (type.includes("no")) {
      return type.slice(3);
    } else {
      return "no_" + type;
    }
  }
  restoreData() {
    //get data and cut out the ending sign
    this.backData = this.initData[this.maskId + "Chart"];
    let dataPoints = this.backData.slice(0, -1);
    // console.log(dataPoints);

    for (let point of dataPoints.split("?")) {
      // controlType, X, Y, pointerType
      let data = point.split(",");
      // console.log(point);
      let _controlType = data[0];
      let _X = data[1];
      let _Y = data[2];
      let _pointerType = data[3];
      this.lineChartData[this.datasetKeys[_controlType]].data[_X] = _Y;
      this.lineChartData[
        this.datasetKeys[this.toggleControlType(_controlType)]
      ].data[_X] = null;
      console.log(_controlType, this.toggleControlType(_controlType));

      this.lineChartData[this.datasetKeys[_controlType]].pointStyle[
        _X
      ] = this.pointerImages[_pointerType];

      this.updateParent();
    }

    for (let cType of ["ac", "bc", "sf", "a"]) {
      this.lineFilter(cType);
    }
  }

  ngOnInit() {
    if (this.initData != "none") {
      let data = this.initData[this.maskId + "Chart"];
      for (let da of data.split("*")) {
        this.initDataset.push(
          da.split(",").map((x) => (x == "" ? null : Number(x)))
        );
      }
      // console.log(this.initDataset);
    }
    this.textClass = this.maskId == "left" ? "blue-text" : "red-text";
    this.chartInit();
  }

  inlineMask() {}

  //take new points using mouse click
  chartClicked(event) {
    var val = this.convert(event.event);
    if (![0, 2, 4].includes(val.X)) {
      let controlType = this.controls.pointerType.slice(0, 2);

      if (!this.controls.noResponse) {
        //have response
        this.lineChartData[this.datasetKeys[controlType]].data[val.X] = this
          .controls.remove
          ? null
          : val.Y;

        //delete the no response data
        this.lineChartData[this.datasetKeys["no_" + controlType]].data[
          val.X
        ] = null;

        this.lineChartData[this.datasetKeys[controlType]].pointStyle[
          val.X
        ] = this.pointerImages[this.controls.pointerType];

        this.backData +=
          controlType +
          "," +
          val.X +
          "," +
          this.lineChartData[this.datasetKeys[controlType]].data[val.X] +
          "," +
          this.controls.pointerType +
          "?";
      } else {
        //do not have response
        //make changes to the according datasets
        this.lineChartData[this.datasetKeys["no_" + controlType]].data[
          val.X
        ] = this.controls.remove ? null : val.Y;

        //delete the no response data
        this.lineChartData[this.datasetKeys[controlType]].data[val.X] = null;

        this.lineChartData[this.datasetKeys["no_" + controlType]].pointStyle[
          val.X
        ] = this.pointerImages["no_" + this.controls.pointerType];

        this.backData +=
          "no_" +
          controlType +
          "," +
          val.X +
          "," +
          this.lineChartData[this.datasetKeys["no_" + controlType]].data[
            val.X
          ] +
          "," +
          "no_" +
          this.controls.pointerType +
          "?";
      }

      //make the line segment transparent
      this.lineFilter(controlType);
      /* console.log(controlType);
      console.log(this.datasetKeys[controlType]);

      console.log(this.lineChartData[this.datasetKeys[controlType]].colors); */
    }
    this.chart.update(); //chart need to updated after adding new data
    this.calculatePTA();
    this.updateParent();
  }

  lineFilter(controlType) {
    for (let i = 1; i <= 16; i++) {
      let doCut: boolean = false;
      let startingIndex = i - 1;
      while (
        this.lineChartData[this.datasetKeys[controlType]].data[i] == null &&
        i <= 16
      ) {
        if (
          this.lineChartData[this.datasetKeys["no_" + controlType]].data[i] !=
          null
        ) {
          doCut = true;
        }
        i++;
      }
      for (let j = startingIndex; j < i; j++) {
        this.lineChartData[this.datasetKeys[controlType]].colors[j] = doCut
          ? "transparent"
          : this.lineColor;
      }
    }
  }
  calculatePTA() {
    try {
      let chartData: number[] = this.lineChartData[0].data as number[];
      let chartDataNo: number[] = this.lineChartData[4].data as number[];
      let total: number = 0;
      let count: number = 0;
      let greaterFlag: boolean = false;
      for (let i of [5, 7, 9]) {
        if (chartData[i] != null) {
          count += 1;
          total += Number(chartData[i]);
        }
        if (chartDataNo[i] != null) {
          count += 1;
          total += Number(chartDataNo[i]);
          greaterFlag = true;
        }
      }
      // console.log(total, count);

      this.PTA1 = (greaterFlag ? ">" : "") + (total / count).toFixed(2);

      //PTA2 calculation
      total = 0;
      count = 0;
      greaterFlag = false;
      for (let i of [11, 7, 9]) {
        if (chartData[i] != null) {
          count += 1;
          total += Number(chartData[i]);
        }
        if (chartDataNo[i] != null) {
          count += 1;
          total += Number(chartDataNo[i]);
          greaterFlag = true;
        }
      }
      this.PTA2 = (greaterFlag ? ">" : "") + (total / count).toFixed(2);
      //console.log(this.PTA1);
    } catch (e) {
      console.log(e);
    }
  }
  ngAfterViewInit() {
    //add eventlistener to canvas, to get mouse hover location
    const canvasElement = (<HTMLElement>(
      this.elementRef.nativeElement
    )).querySelector("#canvas");
    this.renderer.listen(canvasElement, "mousemove", (e) =>
      this.onMouseHover(e)
    );
    this.updateParent();
  }

  //print the mouse location
  onMouseHover(e) {
    var value = this.convert(e);
    this.mouseLoc =
      String(this.lineChartLabels[value.X]) + ", " + String(value.Y);
  }

  //convert pixel co-ordinates to useful data
  convert(e) {
    /*  
      X and Y axis hold 35 & 30 pixels of offset(for 500x500 dimension of the canvas).
      x axis needs to be 16 discrete values(frequencies), y axis needs to be continuous
      from -10 to 120(hearing level).
    */
    //set the offset

    // console.log(e.layerX, e.layerY);

    let xOffset = 35;
    let yOffset = 8;

    //set Max values, for scaling
    let xMax = this.chartWidth - this.chartRightPadding;
    let yMax = this.chartHeight - 55; //55 pixels are taken for labels

    var x = e.layerX - xOffset;
    var y = e.layerY - yOffset;

    //scale and format the pixel values
    // values are collected from trial & error
    y = y * (130 / (yMax-yOffset)) -10 ; //lower limit is -10
    y = 5 * Math.round(y / 5);

    //round y to nearest multiple of 5

    x = Math.round((x * 16) / (xMax-xOffset));

    //clip the values
    y = y >= -10 ? (y <= 120 ? y : 120) : -10;

    x = x >= 0 ? (x <= 16 ? x : 16) : 0;

    return { X: x, Y: y };
  }

  updateParent() {
    //data transformation details on readme.md
    /*     let data: string = "";

    //save the arrays
    for (let charData of this.lineChartData) {
      data += charData.data.join(",") + "*";
    }


    data = data.slice(0, -1);
    //Id of the charts are leftChart and rightChart
    this.dataEvent.emit([this.maskId + "Chart", data]); */

    // this.backData = "plplplplpppllplpplplplplp"
    let data = this.backData;
    // console.log(data);

    this.dataEvent.emit([this.maskId + "Chart", data]);
  }
}

export class Inline {
  value: boolean = false;
}
