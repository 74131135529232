<div style="display: block">
  <!-- <img src="{{ legendImgSrc }}" alt="" /> -->
  <canvas
    baseChart
    width={{chartWidth}}
    height={{chartHeight}}
    id="canvas"
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [colors]="lineChartColors"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
    [plugins]="lineChartPlugins"
    (chartClick)="chartClicked($event)"
    (onmousemove)="onMouseHover($event)"
  >
  </canvas>
  <p class="text-left no-print">Position:{{ mouseLoc }}</p>
  <div class="custom-control custom-switch mt-5 ml-2 no-print">
    <input
      type="checkbox"
      class="custom-control-input"
      id="{{ maskId }}"
      [(ngModel)]="inline.value"
      (ngModelChange)="inlineMask()"
    />
    <label class="custom-control-label hoverHand" for="{{ maskId }}"
      >Inline Mask</label
    >
  </div>
  <hr
    style="
      border: 2px solid red;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    "
  />
  <div class="row">
    <span
      class="col-6 my-1"
      style="font-size: 30; font-weight: bold"
      class="{{ textClass }}"
      >PTA<sub>1</sub>: {{ PTA1 }} dBHL</span
    ><span
      class="col-6 my-1"
      style="font-size: 30; font-weight: bold"
      class="{{ textClass }}"
      >PTA<sub>2</sub>: {{ PTA2 }} dBHL</span
    >
  </div>
</div>
