import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-audiologist-login",
  templateUrl: "./audiologist-login.component.html",
  styleUrls: ["./audiologist-login.component.css"],
})
export class AudiologistLoginComponent implements OnInit {
  constructor(private titleService: Title, private apiService: ApiService) {}
  public data: { [k: string]: any } = {};
  private doSubmit: boolean = true;

  ngOnInit(): void {
    this.titleService.setTitle("Audiologist Login");
  }
  submit() {
    if (this.doSubmit) {
      console.log("submit", this.data);
      
      this.doSubmit = false;
      this.apiService.post('/audiologist/login', this.data)
        .subscribe(
          (res) => {
            // console.log(res.token);
            localStorage.setItem("token", res.token);
            localStorage.setItem("audiologistId", res.audiologistId);

            window.location.href = "/";
          },
          (err) => {
            console.log(err);
            this.doSubmit = true;
            alert("invalid username or password");
          }
        );
    }
  }
}
