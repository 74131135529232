<table class="table table-bordered">
  <thead>
    <tr class="d-flex">
      <th class="col-6 text-center">Audiometric Rinne</th>
      <th class="col-6 text-center">Audiometric Weber</th>
    </tr>
  </thead>
  <tbody>
    <tr class="d-flex">
      <td class="col-3 p-0">
        <app-options
          [id]="'rinne'"
          (dataEvent)="updateParent($event)"
          *ngIf="initData"
          [initData]="initData"
        ></app-options>
      </td>
      <td class="col-3 text-center">
        <!-- <textarea
          name="rinneNote"
          id=""
          cols="18"
          rows="4"
          [(ngModel)]="rinneNote"
          (change)="changed($event)"
        ></textarea> -->
        <p class="only-print" style="font-size: 1.2rem">
          {{ rinneNote }}
        </p>
        <ng2-completer
          [datasource]="rinneSuggestions"
          [minSearchLength]="0"
          name="rinneNote"
          type="text"
          [ngClass]="'audiometric-note rinne no-print'"
          [(ngModel)]="rinneNote"
          (change)="changed($event)"
        >
        </ng2-completer>
      </td>

      <td class="col-3 p-0">
        <app-options
          [id]="'weber'"
          (dataEvent)="updateParent($event)"
          *ngIf="initData"
          [initData]="initData"
        ></app-options>
      </td>
      <td class="col-3 text-center">
        <!-- <textarea
          name="weberNote"
          id=""
          cols="18"
          rows="4"
          [(ngModel)]="weberNote"
          (change)="changed($event)"
        ></textarea> -->
        <p class="only-print" style="font-size: 1.2rem">
          {{ weberNote }}
        </p>
        <ng2-completer
          [datasource]="weberSuggestions"
          [minSearchLength]="0"
          name="weberNote"
          type="text"
          [ngClass]="'audiometric-note weber no-print'"
          [(ngModel)]="weberNote"
          (change)="changed($event)"
        >
        </ng2-completer>
      </td>
    </tr>
  </tbody>
</table>
