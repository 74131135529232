<app-header [title]="'Audiograms'"></app-header>
<div class="container-fluid px-4">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <strong>Audiograms</strong>
              <button class="btn btn-primary btn-sm pull-right mx-1" routerLink="/audiogram-report/{{patientId}}">
                <i class="fa fa-plus"></i> Add New
              </button>
            </div>
          </div>
          <div class="card-body">
            <!-- <custom-tableloader></custom-tableloader> -->
            <!-- <custom-tableoption (onFilterChanged)="fetchPatients(1, $event)"></custom-tableoption> -->

            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th>ID</th>
                        <th>Patient Name</th>
                        <th class="text-center">Last update</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="audiograms.length > 0; else noRecordsFound">
                        <tr *ngFor="let item of audiograms; let i = index">
                          <td>
                            {{
                            Global.getPageNumber({
                            index: i,
                            pageno: 1
                            })
                            }}
                          </td>
                          <td>
                            {{ patientId }}
                          </td>
                          <td>
                            {{ patientName }}
                          </td>

                          <td class="text-center">
                            {{ item?.updatedAt | date:'dd/M/yyyy hh:mm a' }}

                          </td>
                          <td class="text-center">
                            <button routerLink="/audiogram-report/{{patientId}}/{{item?._id}}" class="mx-2"
                              matTooltip="Audiogram" mat-mini-fab>
                              <mat-icon>analytics</mat-icon>
                            </button>

                          </td>
                        </tr>
                      </ng-container>
                      <ng-template #noRecordsFound>
                        <tr>
                          <td class="text-center" colspan="100%">
                            No records found
                          </td>
                        </tr>
                      </ng-template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-warning btn-sm pull-right mx-1" routerLink="/search-patient">
              <i class="fa fa-plus"></i> Back to Previous
            </button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>