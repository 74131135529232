<app-header [title]="pid ?'Update Patient Entry':'New Patient Entry'" class="no-print"></app-header>
<div class="container-fluid px-4">
  <div class="row">
    <form [formGroup]="patientFormGroup" class="col-md-6 no-print">
      <div *ngIf="pid" class="text-center">
        <h2><b>PATIENT ID </b><span class="clon"> : </span> <span>{{pid}}</span> </h2>
      </div>
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="patientName">Patient Name</label>
          <input type="text" [class.is-invalid]="!Global.isInputValid(patientFormGroup, 'name')" formControlName="name"
            class="form-control " id="patientName" placeholder="Patient name" required>
          <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(patientFormGroup, 'name', 'required')">
            This field is required.
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="phone">Phone Number</label>
          <input type="text" [class.is-invalid]="!Global.isInputValid(patientFormGroup, 'phone')" class="form-control"
            id="phone" formControlName="phone" placeholder="Mobile Number" required>
          <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(patientFormGroup, 'phone', 'required')">
            This field is required.
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-3 mb-3">
          <label for="gender">Gender</label>
          <div class="input-group">
            <select class="form-control" [class.is-invalid]="!Global.isInputValid(patientFormGroup, 'gender')"
              formControlName="gender" name="gender">
              <option value=null>Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Female">Other</option>
            </select>
            <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(patientFormGroup, 'gender', 'required')">
              This field is required.
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="age">Age</label>
          <input type="text" [class.is-invalid]="!Global.isInputValid(patientFormGroup, 'age')" formControlName="age"
            class="form-control " id="age" placeholder="age" required>
          <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(patientFormGroup, 'age', 'required')">
            This field is required.
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="age">Referred By</label>
          <ng2-completer [class.is-invalid]="!Global.isInputValid(patientFormGroup, 'referred_by')"
            placeholder="Referred By" required [datasource]="referred_bySuggestions" [minSearchLength]="0"
            formControlName="referred_by" name="referred_by">
          </ng2-completer>
          <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(patientFormGroup, 'referred_by', 'required')">
            This field is required.
          </div>
        </div>

      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="age">Complain</label>
          <ng2-completer [class.is-invalid]="!Global.isInputValid(patientFormGroup, 'complain')" placeholder="complain"
            required [datasource]="complainSuggestions" [minSearchLength]="0" formControlName="complain"
            name="complain">
          </ng2-completer>
          <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(patientFormGroup, 'complain', 'required')">
            This field is required.
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="age">Address</label>
          <ng2-completer [class.is-invalid]="!Global.isInputValid(patientFormGroup, 'address')" placeholder="Address"
            required [datasource]="addressSuggestions" [minSearchLength]="0" formControlName="address" name="address">
          </ng2-completer>
          <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(patientFormGroup, 'address', 'required')">
            This field is required.
          </div>
        </div>
      </div>

    </form>
    <div class="col-md-6 no-print">
      <div class="border p-2">
        <div class="d-flex align-items-center justify-content-between">
          <img class="col-md-4" src="assets/hh_logo.png" alt="Logo" height="60" />
          <h6 class="col-md-4  text-center">Patient Card</h6>
          <div class="d-flex flex-column p-2 col-md-4">
            <b>Phone:</b>
            <span>9333779450</span>
            <span>9883276647</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="p-2 data">
              <b>PID </b><span class="clon">:</span> <span>{{card?.id?card?.id:'NA'}}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="p-2 data">
              <b>Date </b><span class="clon">:</span> <span>{{card?.created?(card?.created):'NA'}}</span>
            </div>
          </div>

          <div class="col-md-6">
            <div class="p-2 data">
              <b>Name </b><span class="clon">:</span> <span>{{card?.name?card?.name:'NA'}}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="p-2 data">
              <b>Mob </b><span class="clon">:</span> <span>{{card?.phone?card?.phone:'NA'}}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="p-2 data">
              <b>Age </b><span class="clon">:</span> <span>{{card?.age?card?.age:'NA'}}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="p-2 data">
              <b>Gender </b><span class="clon">:</span> <span>{{card?.gender?card?.gender:'NA'}}</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="p-2 data">
              <b>Address </b><span class="clon">:</span> <span>{{card?.address?card?.address:'NA'}}</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="p-2 data">
              <b>Complain </b><span class="clon">:</span> <span>{{card?.complain?card?.complain:'NA'}}</span>
            </div>
          </div>
        </div>
        <button class="print-icon-btn" *ngIf="pid" onclick="window.print()" mat-icon-button>
          <mat-icon color="accent">print</mat-icon>
        </button>
      </div>
      <div class="text-right mt-3 no-print">
        <button routerLink="/search-patient" class="btn btn-outline-primary mx-2 col-md-2">
          List
        </button>
        <button (click)="ngOnInit()" class="btn btn-outline-info col-md-2">
          Refresh
        </button>
        <a *ngIf="pid" routerLink="{{ audiogramBackend }}{{ pid }}" class="btn btn-warning mx-2 col-md-2">
          Reports
        </a>
        <button *ngIf="!pid" (click)="reset()" class="btn btn-outline-danger  mx-2 col-md-2">
          Reset
        </button>
        <button (click)="submit($event)" class="btn btn-info col-md-3" type="submit">
          <span class="indicator-label">{{pid?'Update':'Save'}}</span>
          <span class="indicator-progress">{{pid?'Updating..':'Saving..'}}
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="only-print">

    <div class="border p-5 ">
      <div class="d-flex align-items-center justify-content-between">
        <img class="logo" src="assets/hh_logo.png" alt="" />
        <h2>Patient Card</h2>
        <div class="d-flex flex-column">
          <span class="red-text">Call: (033) 2545 7791</span>
          <span class="red-text">Mob.: 9333779450 / 9883276647</span>
          <a class="blue-text text-decoration-none" href="mailto:healthyhearingkol@gmail.com">Email :
            healthyhearingkol@gmail.com</a>
          <a class="blue-text text-decoration-none"
            href="https://hearingaidcenterkolkata.com">www.hearingaidcenterkolkata.com</a>
          <a class="blue-text text-decoration-none" href="https://www.healthyhearing.co.in">www.healthyhearing.co.in</a>

        </div>
      </div>
      <div class="py-3">
        <hr>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="p-2 data">
            <b>PID </b><span class="clon">:</span> <span>{{card?.id?card?.id:'NA'}}</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="p-2 data">
            <b>Date </b><span class="clon">:</span> <span>{{card?.created?(card?.created):'NA'}}</span>
          </div>
        </div>

        <div class="col-md-6">
          <div class="p-2 data">
            <b>Name </b><span class="clon">:</span> <span>{{card?.name?card?.name:'NA'}}</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="p-2 data">
            <b>Mob </b><span class="clon">:</span> <span>{{card?.phone?card?.phone:'NA'}}</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="p-2 data">
            <b>Age </b><span class="clon">:</span> <span>{{card?.age?card?.age:'NA'}}</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="p-2 data">
            <b>Gender </b><span class="clon">:</span> <span>{{card?.gender?card?.gender:'NA'}}</span>
          </div>
        </div>
        <div class="col-md-12">
          <div class="p-2 data">
            <b>Address </b><span class="clon">:</span> <span>{{card?.address?card?.address:'NA'}}</span>
          </div>
        </div>
        <div class="col-md-12">
          <div class="p-2 data">
            <b>Complain </b><span class="clon">:</span> <span>{{card?.complain?card?.complain:'NA'}}</span>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      CHARNOK BHABAN, 12/8 S.M.P. SARANI, NEAR BARRACKPUR STATION, KOLKATA-700120
    </div>
  </div>

</div>