import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class SuggestionService {
  constructor(private apiService: ApiService) {}
  test() {
    console.log("service is working");
  }

  getSuggestions(fieldName: string) {
    return this.apiService.get("/suggestions/" + fieldName
    );
  }
  putSuggestions(fieldName: string, suggestions: string[]) {
    console.log("sending suggestion");
    
    return this.apiService.patch(
      "/suggestions/" + fieldName,
      { fieldName: fieldName, suggestions: suggestions }
    ).subscribe(data=>{
      console.log(data);
      
    });
  }
}
