<button class="no-print" mat-icon-button color="primary" (click)=backClicked() style="float: left; 
        margin: 45px 0px 0px 46px;
        ">
  <mat-icon>arrow_back_ios</mat-icon>
</button>
<div class="print-margin mx-auto">
  <div class="header text-right">
    <img class="text-left logo" [ngClass]="{ 'no-print': removeHeader == true }" src="assets/hh_logo.png" alt="" />

    <div class="float-right" [ngClass]="{ 'no-print': removeHeader == true }">
      <span class="red-text">Call: (033) 2545 7791</span>
      <br />
      <span class="red-text">Mob.: 9333779450 / 9883276647</span>
      <br />
      <a class="blue-text" href="mailto:healthyhearingkol@gmail.com">healthyhearingkol@gmail.com</a>
      <br />
      <a class="blue-text" href="https://www.healthyhearing.co.in">www.healthyhearing.co.in</a>
    </div>
  </div>
  <div class="clearfix no-print">
    <button class="btn btn-lg btn-info float-right mx-2 no-print" (click)="printPage()">
      Print
    </button>
    <button class="btn btn-lg btn-info float-right no-print" (click)="save()">
      {{ buttonAction }}
    </button>

    <div class="float-right mr-5 no-print">
      <input type="checkbox" class="custom-control-input" id="removeHeader" [(ngModel)]="removeHeader" />
      <label class="custom-control-label hoverHand" for="removeHeader">Remove Header</label>
    </div>
  </div>
  <br />

  <app-patient-details class="my-4" [patientId]="patientId" (titleEmitter)="setTitle($event)">
  </app-patient-details>
  <table class="table table-bordered mb-5 float-left">
    <thead>
      <tr class="d-flex">
        <th class="col-5 text-center red-text py-1">RIGHT</th>
        <th class="col-2 text-center p-0"></th>
        <th class="col-5 text-center blue-text py-1">LEFT</th>
      </tr>
    </thead>
    <tbody>
      <tr class="d-flex">
        <td class="col-5 text-center pp" >
          <app-charts *ngIf="initData" [initData]="initData" [maskId]="'right'" [controls]="controls"
            (dataEvent)="audiogramData($event)"></app-charts>
        </td>
        <td class="col-2 text-left mx-auto">
          <app-chart-control *ngIf="initData" [initData]="initData" class="no-print"
            (controlUpdateEvent)="controlUpdate($event)"></app-chart-control>
        </td>
        <td class="col-5 text-center pp">
          <app-charts *ngIf="initData" [initData]="initData" [maskId]="'left'" [controls]="controls"
            (dataEvent)="audiogramData($event)"></app-charts>
        </td>
        <div class="text-left mx-2 only-print custom-font">
          <strong>UNMASKED</strong> <br />
          <strong>AC</strong> <img src="{{ imageLinks.rac }}" class="mx-2" />
          <img src="{{ imageLinks.lac }}" class="mx-2" />
          <br />
          <strong>BC</strong> <img src="{{ imageLinks.rbc }}" class="mx-2" />
          <img src="{{ imageLinks.lbc }}" class="mx-2" />
          <br />
          <br />
          <strong>MASKED</strong> <br />
          <strong>AC</strong> <img src="{{ imageLinks.racm }}" class="mx-2" />
          <img src="{{ imageLinks.lacm }}" class="mx-2" />
          <br />
          <strong>BC</strong> <img src="{{ imageLinks.rbcm }}" class="mx-3" />
          <img src="{{ imageLinks.lbcm }}" class="mx-1" />
          <br />
          <strong>NR</strong> <img src="{{ imageLinks.nr }}" style="height: 30px; width: 65px; margin-left: 8px;" />
          <br />
          <br />
          <div>
            <strong> SOUND-FIELD</strong><strong class="float-right" style="color: black">S</strong>
          </div>
          <div>
            <strong>AIDED</strong><strong class="float-right" style="color: black">A</strong>
          </div>
          <br />
          <br />
          <div>
            <strong>TEST TECHNIQUE :</strong><strong class="float-left" style="color: black">{{
              controls.testTechnique
              }}</strong>
          </div>
          <div>
            <strong>RELIABILITY :</strong>
            <span class="float-left" style="color: black"><span [ngClass]="{
                  'font-weight-bold font-italic': controls.reliability == 'Good'
                }">Good</span>
              /
              <span [ngClass]="{
                  'font-weight-bold font-italic': controls.reliability == 'Fair'
                }">
                Fair</span>
              /
              <span [ngClass]="{
                  'font-weight-bold font-italic': controls.reliability == 'Poor'
                }">Poor</span></span>
          </div>
          <div>
            <strong>VALIDITY :</strong>
            <br />
            <span style="color: black"><span class="float-left" [ngClass]="{
                  'font-weight-bold font-italic':
                    controls.validity == 'Acceptable'
                }">Acceptable/</span><span [ngClass]="{
                  'font-weight-bold font-italic':
                    controls.validity == 'Questionable'
                }">Questionable</span>
            </span>
          </div>
        </div>
      </tr>

      <tr class="d-flex">
        <td class="col-5 text-center" style="min-height: 100px;">
          <!-- <textarea
            class="blue-text"
            name="leftNote"
            rows="3"
            cols="30"
            [(ngModel)]="leftNote"
            (change)="changed($event)"
            >{{ leftNote }}</textarea
          > -->
          <p class="red-text only-print" style="font-size: 1.5rem; line-height: normal;">
            {{ leftNote }}
          </p>
          <ng2-completer [datasource]="leftNoteSuggestions" [minSearchLength]="0" name="leftNote" type="text"
            [ngClass]="'right-note no-print'" [(ngModel)]="leftNote">
          </ng2-completer>
        </td>
        <td class="col-2 text-center"></td>
        <td class="col-5 text-center" style="min-height: 100px;">
          <!-- <textarea
            class="red-text"
            name="rightNote"
            rows="3"
            cols="30"
            [(ngModel)]="rightNote"
            (change)="changed($event)"
            >{{ rightNote }}</textarea
          > -->
          <p class="blue-text only-print" style="font-size: 1.5rem; line-height: normal;">
            {{ rightNote }}
          </p>
          <ng2-completer [datasource]="rightNoteSuggestions" [minSearchLength]="0" name="rightNote" type="text"
            [ngClass]="'left-note no-print'" [(ngModel)]="rightNote">
          </ng2-completer>
        </td>
      </tr>
    </tbody>
  </table>
  <app-audiometric [weberSuggestions]="weberSuggestions" [rinneSuggestions]="rinneSuggestions" *ngIf="initData"
    [initData]="initData" (dataEmitter)="audiogramData($event)"></app-audiometric>
  <div>
    <strong>Recommendation:--</strong>
    <br />
    <ng2-completer [datasource]="recommendationSuggestions" [minSearchLength]="0" type="text"
      class="rcm text-left font-weight-normal" cols="10" rows="2" [(ngModel)]="recommendation"
      [ngClass]="'recommendation'"></ng2-completer>
    <hr class="float-left my-0" style="width: 70%" />
    <ng2-completer [datasource]="recommendation1Suggestions" [minSearchLength]="0" type="text"
      class="rcm text-left font-weight-normal" cols="10" rows="2" [(ngModel)]="recommendation1"
      [ngClass]="'recommendation'"></ng2-completer>
    <hr class="float-left my-0" style="width: 70%" />
    <ng2-completer [datasource]="recommendation2Suggestions" [minSearchLength]="0" type="text"
      class="rcm text-left font-weight-normal" cols="10" rows="2" [(ngModel)]="recommendation2"
      [ngClass]="'recommendation'"></ng2-completer>
    <hr class="float-left my-0" style="width: 70%" />
    <br />
  </div>
  <footer>
    <div class="doc-name text-center my-2">
      <strong> AUDIOLOGIST </strong>
      <br />
      <strong> {{ DocName }}</strong>
      <br />
      <strong> {{ DocDegree }} </strong>
    </div>
    <br>
    
  </footer>
</div>
<div class="brand-footer" [ngClass]="{ 'no-print': removeHeader == true }">
  CHARNOK BHABAN, 12/8 S.M.P. SARANI, NEAR BARRACKPUR STATION, KOLKATA-700 120
</div>