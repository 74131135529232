import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-patient-details",
  templateUrl: "./patient-details.component.html",
  styleUrls: ["./patient-details.component.css"],
})
export class PatientDetailsComponent implements OnInit {
  @Input() patientId: string;
  @Output() titleEmitter = new EventEmitter<String>();
  
  public keyArray;
  today: string = new Date().toLocaleDateString();
  public patient: any = {
    Name: "",
    Age: "",
    Sex: "",
    Purpose: "",
    Date: "",
  };
  //@Input() patient;
  constructor(private apiService: ApiService) {}

  ngOnInit() {
    
    this.apiService
      .get("/audiologist/patient/" + this.patientId)
      .subscribe(
        (data) => {
          this.patient.Name = data.name;
          this.patient.Age = data.age;
          this.patient.Sex = data.gender;
          this.patient.Complain = data.complain;
          this.patient.Date = data.created;
          
          this.titleEmitter.emit(data.name);
          
        },
        (error) => {
          console.log("patient data don't exist");
        }
      );
  }
}
